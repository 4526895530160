$(document).ready(function () {
  // Get clients list
  function getClientsList () {
    // Mock
    var clients = [
      {
        id: 1,
        hq: false,
        code: "12345678",
        cnpj: "11.222.333/0001-01",
        social_reason: "Industria e Comercio de Produtos Farmaceuticos Ltda",
        address: "Rua dos Farmacêuticos, 123, Jardim Industrial, São Paulo-SP",
      },
      {
        id: 2,
        hq: true,
        code: "12345699",
        cnpj: "12.222.323/0001-01",
        social_reason: "Produtos Farmaceuticos Ltda",
        address:
          "Avenida João da Silva Rodrigues, 12345, Distrito Industrial 2, Ribeirão Preto-SP",
      },
      {
        id: 3,
        hq: false,
        code: "13131414",
        cnpj: "15.424.1345/0002-01",
        social_reason: "Industria e Comercio de Produtos Farmaceuticos Ltda",
        address: "Rua dos Farmacêuticos, 123, Jardim Industrial, São Paulo-SP",
      },
      {
        id: 4,
        hq: true,
        code: "14314352",
        cnpj: "15.424.1345/0002-01",
        social_reason: "Industria e Comercio de Produtos Farmaceuticos Ltda",
        address:
          "Avenida João da Silva Rodrigues, 123, Distrito Industrial, São Paulo-SP",
      },
    ];

    var list = [];

    for (var index = 0; index < clients.length; index++) {
      var client = clients[index];
      var isHQ = client.hq ?
        '<button type="button" class="btn-tooltip btn-tooltip-matriz" data-toggle="tooltip" data-placement="top" data-custom-class="tooltip-hq" title="Matriz">' +
        '  <span class="icon material-icons-outlined">home</span>' +
        '</button>'
        : ''
      var template =
        "<tr>" +
        "  <td>" +
        '    <div class="form-group form-choose">' +
        '      <div class="form-choose-inline"><input id="client-' + client.id + '" class="form-choose-input form-choose-client" type="radio" name="client"' + 'value="' + client.id + '">' +
       "     </div>" +
        "   </div>" +
        "  </td>" +
        "  <td class='p-0'>" + isHQ + "</td>" +
        "  <td>" + client.code + "</td>" +
        "  <td>" + client.cnpj + "</td>" +
        "  <td>" + client.social_reason + "</td>" +
        "  <td>" + client.address + "</td>" +
        "</tr>";

      list.push(template);
    }

    list.push('<tr id="no-results-found" style="display: none;"><td class="text-center" colspan="6">Nenhum cliente encontrado para essa busca!</td></tr>')

    return list.join("");
  }

  // Create client modal template
  function createClientModal () {
    var template =
      '<div class="modal fade modal-client" id="modal-client" tabindex="-1">' +
      '    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">' +
      '      <div class="modal-content">' +
      '        <div class="modal-header">' +
      '          <button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
      '            <span class="icon material-icons-outlined">close</span>' +
      "          </button>" +
      "        </div>" +
      '        <div class="modal-body">' +
      '          <div class="modal-title">Selecione o cliente abaixo para iniciar o pedido:</div>' +
      '          <div class="modal-search">' +
      '            <div class="form-group form-search">' +
      '              <div class="form-group-container">' +
      '                <input type="text" class="form-control" id="client-search" name="search" placeholder="Pesquisar">' +
      '                <button type="button">' +
      '                  <span class="icon material-icons-outlined">' +
      "                    search" +
      "                  </span>" +
      "                </button>" +
      "              </div>" +
      "            </div>" +
      "          </div>" +
      '          <div class="modal-table">' +
      '            <div class="table-responsive">' +
      '              <table class="table table-default table-clients" id="table-clients">' +
      "                <thead>" +
      "                  <tr>" +
      '                    <th class="column-small" width="5%"></th>' +
      '                    <th class="column-small p-0" width="1%"></th>' +
      '                    <th class="column-small" width="10%">' +
      "                      Código" +
      "                    </th>" +
      '                    <th width="20%">' +
      "                      CNPJ" +
      "                    </th>" +
      '                    <th width="25%">' +
      "                      Razão Social" +
      "                    </th>" +
      '                    <th width="30%">' +
      "                      Endereço" +
      "                    </th>" +
      "                  </tr>" +
      "                </thead>" +
      "                <tbody>" + getClientsList() + "</tbody>" +
      "              </table>" +
      "            </div>" +
      "          </div>" +
      '          <div class="modal-actions">' +
      '            <div class="row align-items-center justify-content-end">' +
      '              <div class="col-lg-3">' +
      '                <button type="button" class="btn btn-orange btn-lg" id="btn-choose-client" data-dismiss="modal" disabled>' +
      "                  Selecionar" +
      "                </button>" +
      "              </div>" +
      "            </div>" +
      "          </div>" +
      "        </div>" +
      "      </div>" +
      "    </div>" +
      "  </div>";

    return template;
  }

  // Open client modal
  function openClientModal () {
    var template = createClientModal();
    var body = $("body");
    var findModal = body.find("#modal-client");

    if (findModal.length === 0) {
      body.prepend(template);
    }

    var modal = $("#modal-client");

    modal.modal("show");
    initTooltips()
  }

  // On search click
  $(document).on("click", "#header-search", function () {
    var target = $(this);
    var header = $("#header");
    var headerSearch = header.find(".header-search");
    var wasOpened = header.hasClass("search-opened");
    var searchIcon = target.find(".search-icon");
    var closeIcon = target.find(".close-icon");

    if (wasOpened) {
      headerSearch.fadeOut();
      closeIcon.fadeOut(300, function () {
        searchIcon.fadeIn();
      });
    } else {
      headerSearch.fadeIn();
      searchIcon.fadeOut(300, function () {
        closeIcon.fadeIn();
      });
    }

    header.toggleClass("search-opened");
  });

  // On client search
  $(document).on("input", "#client-search", function () {
    var target = $(this);
    var searchValue = target.val().toLowerCase()
    var table = $('#table-clients')
    var tableRows = table.find('tbody tr')
    var noResultsFounded = table.find('#no-results-found')
    var hasRowsToShow = false

    if (searchValue) {
      tableRows.each(function (index, row) {
        var tableRow = $(row)
        var tableItems = tableRow.find('td')
        var showRow = false

        tableItems.each(function (index, item) {
          var tableItem = $(item)
          var textValue = tableItem.text().toLowerCase().trim().replace(/ /g, '')

          if (textValue.indexOf(searchValue) > -1) {
            showRow = true
          }
        })

        if (showRow) {
          tableRow.show()
          hasRowsToShow = true
        } else {
          tableRow.hide()
        }
      })
    } else {
      hasRowsToShow = true
      tableRows.each(function (index, row) {
        var tableRow = $(row)
        tableRow.show()
      })
    }

    if (!hasRowsToShow) {
      noResultsFounded.show()
    } else {
      noResultsFounded.hide()
    }
  });

  // On client choose
  $(document).on('change', '.form-choose-client', function () {
    var target = $(this)
    var isChecked = target.is(':checked')
    var button = $('#btn-choose-client')

    isChecked ? button.prop('disabled', false) : button.prop('disabled', true)
  })

  // Open client modal
  $(document).on("click", ".open-client-modal", function () {
    openClientModal();
  });

  $(document).on("click", ".header-nav a", function () {
    var target = $(this);
    var targetIsActive = target.hasClass("active");

    if (!targetIsActive) {
      var allNavLinks = $(".header-nav a");
      allNavLinks.removeClass("active");

      target.addClass("active");
    }
  });

  // On hover dropdown
  $(".dropdown-user").mouseenter(function () {
    var isShowing = $(this).hasClass("show");
    if (!isShowing) {
      $(this).find(".dropdown-toggle").click();
    }
  });

  // On leave dropdown
  $(".dropdown-user").mouseleave(function () {
    var isShowing = $(this).hasClass("show");
    if (isShowing) {
      $(this).find(".dropdown-toggle").click();
    }
  });
});
