$(document).ready(function () {
  var cart = [];

  // Handle discounts
  function handleProductsPrice (product, quantity) {
    var originalPrice = product.find(".card-product-price-original");
    var currentPrice = product.find(".card-product-price-current");
    var data = product.find(".data");

    if (data && data.val()) {
      var productData = JSON.parse(data.val());
      var progressiveDiscounts = productData.discounts;
      var discount = productData.discount;
      var price = productData.price;
      var discountedPrice;

      if (progressiveDiscounts && progressiveDiscounts.length > 0) {
        var appliedDiscount = 0;

        for (var index = 0; index < progressiveDiscounts.length; index++) {
          var currentDiscount = progressiveDiscounts[index];

          if (
            quantity >= (currentDiscount.min || 0) &&
            quantity <= (currentDiscount.max || Infinity)
          ) {
            appliedDiscount = (currentDiscount.percentage / 100).toFixed(2);
          }
        }

        if (appliedDiscount) {
          discountedPrice = price - price * appliedDiscount;
          originalPrice.text(toLocalCurrency(price));
          currentPrice.text(toLocalCurrency(discountedPrice));
        } else {
          originalPrice.text("");
          currentPrice.text(toLocalCurrency(price));
        }
      } else if (discount) {
        appliedDiscount = (discount / 100).toFixed(2);
        discountedPrice = price - price * appliedDiscount;
        originalPrice.text(toLocalCurrency(price));
        currentPrice.text(toLocalCurrency(discountedPrice));
      }

      return {
        price: discountedPrice || price || 0,
        originalPrice: price || 0,
        discount: appliedDiscount || 0,
      };
    }
  }

  // Update cart list of products
  function handleProductsUpdate (product, quantity) {
    var data = product.find(".data");

    if (data && data.val()) {
      var productPrice = handleProductsPrice(product, quantity);
      var productData = JSON.parse(data.val());

      if (quantity > 0) {
        var item = {
          id: productData.id,
          title: productData.title,
          originalPrice: productPrice.originalPrice,
          price: productPrice.price,
          discount: productPrice.discount,
          quantity: quantity,
        };
        updateOrAddProduct(item);
      } else {
        removeProduct(productData.id);
      }
    }

    updateCart();
  }

  // Set product active
  function setProductActive (product, quantity) {
    if (quantity > 0) {
      product.addClass("active");
    } else {
      product.removeClass("active");
    }
  }

  // Update cart
  function updateCart () {
    updateCartTable();
    updateCartResume();
    updateCartBadge();
    handleSubmitButton();
  }

  // Update cart table
  function updateCartTable () {
    var cartProducts = $("#cart-products");
    var cartProductsList = cartProducts.find(".card-info-body-items");
    var listItems = [];

    if (cart && cart.length > 0) {
      for (var index = 0; index < cart.length; index++) {
        var product = cart[index];

        listItem = "<li class='card-info-body-item'>" + product.title;
        listItem += "<ul>";
        listItem += "  <li>";
        listItem += "    <b>QUANTIDADE</b>";
        listItem +=
          "    <span>" +
          new Intl.NumberFormat("pt-BR").format(product.quantity) +
          "</span>";
        listItem += "  </li>";
        listItem += "  <li>";
        listItem += "    <b>VALOR UNITÁRIOoo</b>";
        listItem += "    <span>" + toLocalCurrency(product.price) + "</span>";
        listItem += "  </li>";
        listItem += "  <li>";
        listItem += "    <b>VALOR TOTAL</b>";
        listItem +=
          "    <span>" +
          toLocalCurrency(product.price * product.quantity) +
          "</span>";
        listItem += "  </li>";
        listItem += "</ul>";
        listItem += "</li>";

        listItems.push(listItem);
      }

      cartProductsList.html(listItems.join(""));
      cartProducts.show();
    } else {
      cartProducts.hide();
    }
  }

  // Update cart resume
  function updateCartResume () {
    var cartResume = $("#cart-resume");
    var cartOverall = cartResume.find("#cart-overall");
    var cartDiscount = cartResume.find("#cart-discount");
    var cartTotal = cartResume.find("#cart-total");
    var cartQuantity = cartResume.find("#cart-quantity");

    var resume = {
      overall: 0,
      discount: 0,
      total: 0,
      quantity: 0,
    };

    if (cart && cart.length > 0) {
      for (var index = 0; index < cart.length; index++) {
        var product = cart[index];
        resume.overall += product.originalPrice * product.quantity;
        resume.total += product.price * product.quantity;
        resume.quantity += product.quantity;
      }
    }

    var totalDiscount = resume.overall - resume.total;

    cartOverall.text(toLocalCurrency(resume.overall));
    cartDiscount.text(toLocalCurrency(totalDiscount));
    cartTotal.text(toLocalCurrency(resume.total));
    cartQuantity.text(resume.quantity);
  }

  // Update badge on header
  function updateCartBadge () {
    var headerBadge = $("#header .badge-cart");

    if (cart && cart.length > 0) {
      headerBadge.text(cart.length);
      headerBadge.show();
    } else {
      headerBadge.text(0);
      headerBadge.hide();
    }
  }

  // Up or Insert in array
  function updateOrAddProduct (item) {
    var index = -1;

    cart.some(function (_item, i) {
      if (_item.id === item.id) {
        index = i;
        return true;
      }
    });

    if (index > -1) {
      cart[index] = item;
    } else {
      cart.push(item);
    }
  }

  // Remove product
  function removeProduct (id) {
    var indexOf = getProductIndex("id", id);

    if (indexOf >= 0) {
      cart.splice(indexOf, 1);
    }
  }

  // Get product index by key
  function getProductIndex (key, val) {
    for (var i = 0; i < cart.length; i++) {
      if (cart[i][key] == val) return i;
    }
    return -1;
  }

  // Convert to local currency
  function toLocalCurrency (value) {
    return value.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }

  // Handle with show/hide submit button
  function handleSubmitButton () {
    var button = $('#cart-form button[type="submit"]');
    if (cart && cart.length > 0) {
      button.removeAttr("disabled");
    } else {
      button.prop("disabled", true);
    }
  }

  // Handle with info cards on mobile
  function handleInfoCardsOnMobile () {
    if ($(window).width() < 992) {
      $(".card-info").each(function (index, item) {
        var card = $(item);
        var isTogglable = card.find(".card-info-toggle");
        var cardContent = card.find(".card-info-body");

        if (isTogglable.length > 0) {
          card.addClass("closed");
          cardContent.animate({ height: 0 }, 300, function () {
            cardContent.hide();
          });
        }
      });
    }
  }

  // On product quantity change
  $(document).on("input", ".form-quantity-input", function () {
    var target = $(this);
    var quantity = target.val() ? parseInt(target.val()) : 0;
    var product = target.closest(".card-product");

    handleProductsPrice(product, quantity);
    handleProductsUpdate(product, quantity);
    setProductActive(product, quantity);
  });

  // Toggle bookmark
  $(document).on("click", ".card-product-bookmark", function () {
    var target = $(this);
    var bookmarked = target.hasClass("bookmarked");
    var bookmarkIcon = target.find(".icon");

    if (bookmarked) {
      bookmarkIcon.text("star_outline");
    } else {
      bookmarkIcon.text("star");
    }

    target.toggleClass("bookmarked");
  });

  // Toggle card info
  $(document).on("click", ".card-info-toggle", function () {
    var target = $(this);
    var card = target.closest(".card-info");
    var cardWasClosed = card.hasClass("closed");
    var cardContent = card.find(".card-info-body");

    card.toggleClass("closed");

    if (cardWasClosed) {
      cardContent.show();
      cardContent.animate(
        { height: $(cardContent)[0].scrollHeight + "px" },
        300
      );
    } else {
      cardContent.animate({ height: 0 }, 300, function () {
        cardContent.hide();
      });
    }
  });

  // On init get all product cards and calculate discounts
  $(".card-product").each(function (index, product) {
    handleProductsPrice($(product), 0);
  });

  // Validate order
  $("#cart-form").validate({
    ignore: ".ignore",

    rules: {
      order_deadline: {
        required: true,
      },
    },

    messages: {
      order_deadline: {
        required: "É obrigatório escolher um prazo para o pedido!",
      },
    },

    errorElement: "em",

    errorPlacement: function (error, element) {
      // Add the `invalid-feedback` class to the error element
      error.addClass("invalid-feedback");

      if (element.prop("type") === "checkbox") {
        error.insertAfter(element.next("label"));
      } else {
        error.insertAfter(element);
      }
    },

    submitHandler: function (form, event) {
      event.preventDefault();

      console.log("form: ", $(form).serialize());

      window.location.href = "pedido-fracionamento.html";
    },

    invalidHandler: function () {
      var card = $("#cart-fields");
      var cardContent = card.find(".card-info-body");
      var margin = 20;

      if (card.hasClass("closed")) {
        card.removeClass("closed");
        cardContent.show();
        cardContent.animate(
          { height: $(cardContent)[0].scrollHeight + margin + "px" },
          300
        );
      }

      window.modalHandler.create({
        title: "Atenção!",
        message:
          "Preencha <b>os dados do pedido</b> para continuar com a sua compra!",
        type: "warning",
      });
    },
  });

  // On init
  handleInfoCardsOnMobile();
  updateCart();
});
