var modalHandler = {
  template: function (config) {
    var template =
      '<div class="modal modal-custom fade modal-' +
      config.type +
      '" id="custom-modal" tabindex="-1">' +
      '  <div class="modal-dialog modal-dialog-centered">' +
      '    <div class="modal-content">' +
      '      <div class="modal-header"> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span' +
      '            class="icon material-icons-outlined">close</span> </button> </div>' +
      '      <div class="modal-body">' +
      '        <div class="modal-icon"> <img src="../assets/img/modal/' +
      config.type +
      '.svg" alt="Tipo do alerta"> </div>' +
      '        <div class="modal-title">' +
      config.title +
      "</div>" +
      '        <div class="modal-message">' +
      config.message +
      "</div>" +
      "      </div>" +
      "    </div>" +
      "  </div>" +
      "</div>";

    return template;
  },

  create: function (config) {
    var baseConfig = {
      title: config.title || "",
      message: config.message || "",
      type: config.type || "info", // success, danger, warning & info
    };
    var template = this.template(baseConfig);
    var body = $("body");

    var customModal = body.find("#custom-modal");
    if (customModal) customModal.remove();

    body.prepend(template);

    var modal = $("#custom-modal");
    modal.modal("show");
    modal.on("hidden.bs.modal", function (event) {
      modal.remove();
    });
  },
};
